var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Link } from 'nuri';
import * as Layout from './Layout';
import LoginDialog from './LoginDialog';
import SearchInput from './SearchInput';
import { Dropdown } from './Dropdown';
import Styles from './GlobalHeader.less';
import { getStatusDisplayGql } from '../util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faHome, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { graphql } from '../API';
import { GlobalHeader_QuickRecordsDocument } from './__generated__/GlobalHeader.graphql';
var DropdownUserMenu = /** @class */ (function (_super) {
    __extends(DropdownUserMenu, _super);
    function DropdownUserMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            records: null,
        };
        return _this;
    }
    DropdownUserMenu.prototype.componentDidMount = function () {
        var _this = this;
        graphql(GlobalHeader_QuickRecordsDocument).then(function (result) {
            var _a, _b;
            _this.setState({ records: (_b = (_a = result.currentUser) === null || _a === void 0 ? void 0 : _a.records.nodes) !== null && _b !== void 0 ? _b : null });
        });
    };
    DropdownUserMenu.prototype.render = function () {
        var records = this.state.records;
        return (React.createElement(React.Fragment, null,
            React.createElement(Link, { to: "/users/".concat(this.props.username, "/") }, "\uB0B4 \uAE30\uB85D"),
            React.createElement(Link, { to: "/settings/" }, "\uC124\uC815"),
            records && (React.createElement("div", null,
                React.createElement("div", { className: Styles.userMenuSeparator }, "\uBC14\uB85C\uAC00\uAE30"),
                records.map(function (record) { return (React.createElement(Link, { to: "/records/".concat(record.databaseId, "/") },
                    record.title,
                    React.createElement("span", { className: Styles.quickRecordStatus }, getStatusDisplayGql(record)))); }),
                React.createElement(Link, { to: "/users/".concat(this.props.username, "/"), className: Styles.quickRecordViewAll }, "\uC804\uCCB4 \uBCF4\uAE30")))));
    };
    return DropdownUserMenu;
}(React.Component));
var GlobalHeader = /** @class */ (function (_super) {
    __extends(GlobalHeader, _super);
    function GlobalHeader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showNotice: false,
        };
        _this._openLoginIfNeeded = function (e) {
            if (!_this.props.currentUsername) {
                e.preventDefault();
                LoginDialog.open({ next: { redirectToUser: true } }); // FIXME
            }
        };
        _this._openLogin = function (e) {
            if (e)
                e.preventDefault();
            LoginDialog.open();
        };
        _this._closeLogin = function () {
            LoginDialog.close();
        };
        _this._saveNoticeClicked = function () {
            try {
                window.localStorage.setItem(GlobalHeader.LAST_NOTICE_CLICKED, GlobalHeader.noticeId);
                _this.setState({ showNotice: false });
            }
            catch (e) {
                // ignore
            }
        };
        return _this;
    }
    GlobalHeader.prototype.componentDidMount = function () {
        try {
            if (window.localStorage.getItem(GlobalHeader.LAST_NOTICE_CLICKED) !== GlobalHeader.noticeId &&
                Date.now() < GlobalHeader.showNoticeUntil.getTime()) {
                this.setState({ showNotice: true });
            }
        }
        catch (e) {
            // ignore
        }
    };
    GlobalHeader.prototype.render = function () {
        var activeMenu = this.props.activeMenu;
        var username = this.props.currentUsername;
        var showNotice = this.state.showNotice && activeMenu !== 'search' && !this.props.noNotice;
        return (React.createElement("div", { className: Styles.container },
            React.createElement(Layout.CenteredFullWidth, { className: Styles.header },
                React.createElement("div", { className: Styles.headerInner },
                    React.createElement("div", { className: "".concat(Styles.logo, " hide-mobile") },
                        React.createElement(Link, { to: "/" }, "\uC560\uB2C8\uBA54\uD0C0")),
                    React.createElement("div", { className: Styles.globalMenu },
                        React.createElement(Link, { to: "/", className: activeMenu === 'home'
                                ? Styles.activeGlobalMenuItem
                                : Styles.globalMenuItem },
                            React.createElement("span", null,
                                React.createElement(FontAwesomeIcon, { icon: faHome, size: "sm" })),
                            React.createElement("span", { className: Styles.globalMenuItemText }, "\uD648")),
                        React.createElement(Link, { to: "/table/", className: activeMenu === 'search'
                                ? Styles.activeGlobalMenuItem
                                : Styles.globalMenuItem, onClick: this._saveNoticeClicked },
                            React.createElement("span", null,
                                React.createElement(FontAwesomeIcon, { icon: faSearch, size: "sm" })),
                            React.createElement("span", { className: Styles.globalMenuItemText }, "\uC791\uD488 \uCC3E\uAE30"),
                            showNotice && (React.createElement("span", { className: Styles.globalMenuItemPopover }, "7\uC6D4 \uC2E0\uC791 \uC5C5\uB370\uC774\uD2B8!"))),
                        React.createElement(Link, { to: username
                                ? "/users/".concat(username, "/")
                                : '/login/', className: activeMenu === 'user'
                                ? Styles.activeGlobalMenuItem
                                : Styles.globalMenuItem, onClick: this._openLoginIfNeeded },
                            React.createElement("span", null,
                                React.createElement(FontAwesomeIcon, { icon: faUser, size: "sm" })),
                            React.createElement("span", { className: Styles.globalMenuItemText }, "\uB0B4 \uAE30\uB85D"))),
                    React.createElement("div", { style: { flex: 1 }, className: "hide-mobile" }),
                    React.createElement("div", { className: Styles.search },
                        React.createElement(SearchInput, null)),
                    username && (React.createElement(Dropdown, { containerClassName: "".concat(Styles.accountMenu, " hide-mobile"), contentClassName: Styles.userMenu, renderTrigger: function (_a) {
                            var toggle = _a.toggle;
                            return (React.createElement(Link, { to: "/users/".concat(username, "/"), className: Styles.userButton, onClick: toggle },
                                React.createElement(FontAwesomeIcon, { icon: faUser }),
                                username,
                                React.createElement(FontAwesomeIcon, { icon: faCaretDown })));
                        } },
                        React.createElement(DropdownUserMenu, { username: username }))))),
            !this.props.currentUsername &&
                !this.props.noHero && (React.createElement("div", { className: Styles.hero },
                React.createElement(Layout.CenteredFullWidth, null,
                    React.createElement("div", { className: Styles.slogan },
                        "\uC560\uB2C8 \uBCF4\uACE0 \uB098\uC11C,",
                        ' ',
                        React.createElement("span", { className: Styles.sloganBrand }, "\uC560\uB2C8\uBA54\uD0C0")),
                    React.createElement("div", { className: Styles.subSlogan },
                        "\uC560\uB2C8\uBA54\uD0C0\uB294 \uC560\uB2C8\uBA54\uC774\uC158 \uAC10\uC0C1 \uAE30\uB85D\uC7A5\uC785\uB2C8\uB2E4.",
                        React.createElement("br", null),
                        "\uC5B4\uB5A4 \uC791\uD488\uC744 \uBA87 \uD654\uAE4C\uC9C0 \uBD24\uB294\uC9C0 \uAC10\uC0C1\uD3C9\uACFC \uD568\uAED8 \uAE30\uB85D\uD558\uC2E4 \uC218 \uC788\uC5B4\uC694."),
                    React.createElement("div", { className: Styles.heroActions },
                        React.createElement("a", { href: "/login/", onClick: this._openLogin, className: Styles.heroLoginButton }, "\uB85C\uADF8\uC778"),
                        React.createElement(Link, { to: "/signup/", className: Styles.heroSignUpButton }, "\uAC00\uC785\uD558\uAE30")))))));
    };
    GlobalHeader.LAST_NOTICE_CLICKED = 'lastNoticeClicked';
    GlobalHeader.noticeId = '2024Q3';
    GlobalHeader.showNoticeUntil = new Date("2024-07-15T00:00:00+09:00");
    return GlobalHeader;
}(React.Component));
export { GlobalHeader };
